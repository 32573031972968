$image-path: "../../assets/hero-image.png";

.banner-bg {
  background-image: url($image-path);
  background-repeat: repeat;
  background-size: cover;
  .banner-content {
    .header-form {
      background-color: rgba(59, 55, 53, 0.8);
    }
  }
  h2 {
    .underline-text {
      display: inline-block;
      position: relative;
      font-size: 32px;
      font-weight: bold;
      color: #fff;
      &::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 2px;
        background-color: #fff;
        top: 120%;
        left: 0;
      }
    }
  }
  .move-down {
    position: absolute;
    bottom: 32px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
  }
}

@mixin pulse {
  @keyframes pulse {
    0% {
      -moz-transform: scale(1);
      transform: scale(1);
    }

    50% {
      -moz-transform: scale(1.2);
      transform: scale(1.2);
    }

    100% {
      -moz-transform: scale(1);
      transform: scale(1);
    }
  }
  animation: pulse 1.5s infinite;
}
.animated-chevron {
  -webkit-transition-property: -webkit-transform;
  transition-property: transform;
  -webkit-transition-duration: 1s;
  transition-duration: 1s;
  -moz-transition-property: -moz-transform;
  -moz-transition-duration: 1s;
  @include pulse;
  cursor: pointer;
}
