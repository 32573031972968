@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Raleway:ital,wght@0,200;0,300;0,400;0,600;0,700;1,100&display=swap");
:root {
  --sea-green: #0e948d;
  --sea-green-hover: #0db0a8;
  --sea-blue: #085f84;
  --lead: #7c7c7c;
  --black-800: #2a313d;
  --black: #000000;
  --white: #ffffff;
  --font-family-secondary: "Raleway", sans-serif;
  --font-family-main: "Poppins", sans-serif;
}

// Base Settings
body {
  overflow-x: hidden;
  background-color: #36454F;;
  font-family: var(--font-family-main);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-family-main);
}
h1 {
  font-family: var(--font-family-main);
  font-size: 44px;
  font-weight: bold;
  line-height: 60px;
  color: var(--black);
  @media (max-width: 767px) {
    font-size: 36px;
    line-height: 48px;
  }
}

h2 {
  font-family: var(--font-family-main);
  font-size: 32px;
  font-weight: bold;
  line-height: 52px;
  color: var(--black);
  @media (max-width: 767px) {
    font-size: 28px;
    line-height: 40px;
  }
}

h3 {
  font-family: var(--font-family-main);
  font-size: 28px;
  font-weight: bold;
  line-height: 36px;
  @media (max-width: 767px) {
    font-size: 22px;
    line-height: 30px;
  }
}
h4 {
  font-family: var(--font-family-main);
  font-size: 24px;
  font-weight: 700;
  @media (max-width: 767px) {
    font-size: 20px;
  }
}
h5 {
  font-family: var(--font-family-main);
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  color: #041b29;
}
h6 {
  font-family: var(--font-family-main);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #041b29;
}
p,
span {
  font-family: var(--font-family-main);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  color: #262626;
  @media (max-width: 575px) {
    font-size: 16px;
  }
}
li {
  font-family: var(--font-family-main);
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #6e7072;
}
label {
  font-family: var(--font-family-secondary);
}
.copyright {
  font-family: var(--font-family-main);
}
.footer-text {
  font-size: 14px;
  text-decoration-line: underline !important;
  @media (max-width: 575px) {
    font-size: 12px;
  }
}
.link-btn {
  font-family: var(--font-family-main);
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  color: #041b29;
  padding-bottom: 8px;
  border-bottom: 1px solid #b8b8b8;
  &:hover {
    color: #041b29;
  }
}
.link-btn-white {
  font-family: var(--font-family-main);
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  color: #fff;
  padding-bottom: 8px;
  border-bottom: 1px solid #b8b8b8;
  &:hover {
    color: #eeeeee;
  }
}
.texto-description {
  color: #b8b8b8;
}
.nav-link {
  color: #fff;
  font-family: var(--font-family-secondary);
  &:hover {
    color: #eeeded;
  }
}
