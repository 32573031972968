$image-path: "../../assets/hero-image.png";

.banner-bg {
  background-image: url($image-path);
  background-repeat: repeat;
  background-size: cover;
  .banner {
    height: 80vh;
    .header-form {
      background-color: rgba(59, 55, 53, 0.8);
    }
  }
  h2 {
    .underline-text {
      display: inline-block;
      position: relative;
      font-size: 32px;
      font-weight: bold;
      color: #fff;
      &::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 2px;
        background-color: #fff;
        top: 120%;
        left: 0;
      }
    }
  }
}
