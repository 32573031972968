$primary: #f7941d;
$secondary: #041b29;
$success: #39b54a;
$info: #e5e5e5;
$warning: #3b3735;
$danger: #8c8c8c;

// Default variable overrides
$body-bg: #fff;
$body-color: #262626;

// nav-toggler focus border
$navbar-toggler-border-color: $secondary;
$dropdown-link-hover-bg: rgba(4, 27, 41, 0.1);
// enale shadows
$enable-shadows: true;
$input-box-shadow: 0;
// accordion variables
$accordion-button-active-bg: transparent;
$accordion-button-focus-box-shadow: transparent;
$accordion-icon-width: 1rem;
$accordion-border-width: 0px;
// box-shadow
$box-shadow-sm: 0.05 0.125rem 0.25rem rgba(blue, 0.095);
$box-shadow-lg: 0.75rem 0.75rem 2rem rgba(blue, 0.1);
// pagination seeting you can customize
// navigation with deifferent options here
$pagination-font-size: 1.2rem;
$pagination-border-radius: 0rem;
// breadcrumb
$breadcrumb-item-active-color: $secondary;
$breadcrumb-divider-color: #dadee2;
//
// form select
$form-select-box-shadow: 0px;
$form-select-focus-border-color: #000;
$input-focus-border-color: #000;
//custom spacing for margin and padding
$spacer: 1rem !default;
$spacers: (
  // from 0 to 10 for giving spacing for elements
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer * 0.75,
  4: $spacer,
  5: $spacer * 1.25,
  6: $spacer * 1.5,
  7: $spacer * 1.75,
  8: $spacer * 2,
  9: $spacer * 2.25,
  10: $spacer * 2.5,
  11: $spacer * 2.75,
  12: $spacer * 3,
  13: $spacer * 3.25,
  14: $spacer * 3.5,
  // spacing for sections
  60: $spacer * 3.75,
  64: $spacer * 4,
  68: $spacer * 4.25,
  72: $spacer * 4.5,
  80: $spacer * 5,
  100: $spacer * 6.25,
  120: $spacer * 7.5,
  192: $spacer * 12
);
// breakpoints values for different screens
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1600px,
  xxxxl: 1800px,
);
// maximum widths for relative breakpoints mentioned above
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1000px,
);
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/maps";
@import "../../../node_modules/bootstrap/scss/mixins";
@import "../../../node_modules/bootstrap/scss/root";
// Optional
@import "../../../node_modules/bootstrap/scss/reboot";
@import "../../../node_modules/bootstrap/scss/type";
@import "../../../node_modules/bootstrap/scss/utilities";
@import "../../../node_modules/bootstrap/scss/utilities/api";
$utilities: map-merge(
  $utilities,
  (
    "width":
      map-merge(
        map-get($utilities, "width"),
        (
          responsive: true,
        )
      ),
  )
);
// etcs
@import "../../../node_modules/bootstrap/scss/accordion";
@import "../../../node_modules/bootstrap/scss/bootstrap";
// google fonts
@import url("https://fonts.googleapis.com/css2?family=Exo:wght@100;200;300;400;500;600;700;800;900&family=Lora:wght@400;500;600;700&display=swap");
// google fonts end
@import "./_varaibles";
@import "./_utilities";

a {
  text-decoration: none !important;
  &:hover {
    text-decoration: none;
  }
}

li {
  list-style: none;
}
ul {
  list-style: none;
}

::-webkit-input-placeholder {
  font-family: "mulish" !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #808080 !important;
}

:-moz-placeholder {
  font-family: "mulish" !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #808080 !important;
}
:-ms-input-placeholder {
  font-family: "mulish" !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #808080 !important;
}
