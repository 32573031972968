.left-quote,
.right-quote {
  position: absolute;
  height: auto;
  max-width: 200px;
  @media (max-width: 575px) {
    max-width: 120px;
  }
  @media (max-width: 390px) {
    max-width: 100px;
  }
}
.left-quote {
  left: 10%;
  top: 10px;
}
.right-quote {
  right: 10%;
  bottom: 10px;
}
